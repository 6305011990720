
import Vue, { PropType } from "vue";

import MyEditor from "@/components/notice/MyEditor.vue";

import http from "@/http";

import { Notice, Member } from "@/interfaces";

import { mdiAttachment } from "@mdi/js";

export interface DataType {
  notice?: Notice;
  attachments: File[];
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true
    }
  },
  data(): DataType {
    return {
      notice: undefined,
      attachments: []
    };
  },
  computed: {
    icon() {
      return { mdiAttachment };
    }
  },
  mounted() {
    this.notice = {
      id: 0,
      title: "",
      content: "",
      status: "publish",
      attachments: [],
      created_at: "",
      updated_at: "",
      is_notify: true
    };
  },
  methods: {
    async save(i: Notice) {
      const { data } = await http.post<Notice>(
        `members/${this.member.id}/notices`,
        {
          ...this.notice,
          ...i
        }
      );

      const id = data.id;

      const url = `notices/${id}/attachments`;

      for (const attachment of this.attachments) {
        const formData = new FormData();

        formData.append("file", attachment);

        await http.post<Notice>(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }

      this.$router.push(`/member/${this.member.id}`);
    }
  }
});
